import type { SearchAggregationsQueryVariables } from '#gql'

export function useSearchQueries() {
  const getSearchAggregations = async (variables: SearchAggregationsQueryVariables) => {
    return await GqlSearchAggregations(variables)
  }

  return {
    getSearchAggregations,
  }
}
