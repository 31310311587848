import validate from "/home/ecomadm/app/releases/168/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/validate.js";
import section_45data_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/middleware/sectionData.global.ts";
import redirects_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/middleware/redirects.global.ts";
import non_45regional_45url_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-cms/middleware/non-regional-url.global.ts";
import conditional_45router_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-base/middleware/conditional-router.global.ts";
import layouts_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-base/middleware/layouts.global.ts";
import session_45storage_45global from "/home/ecomadm/app/releases/168/modules/rawlplug-base/middleware/session-storage.global.ts";
import manifest_45route_45rule from "/home/ecomadm/app/releases/168/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  section_45data_45global,
  redirects_45global,
  non_45regional_45url_45global,
  conditional_45router_45global,
  layouts_45global,
  session_45storage_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/middleware/auth.ts"),
  "system-role": () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/middleware/systemRole.ts"),
  checkout: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/middleware/checkout.ts"),
  register: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/middleware/register.ts"),
  "resolve-route": () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/middleware/resolveRoute.ts"),
  search: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-base/middleware/search.ts")
}