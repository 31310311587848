import type { ComputedRef } from 'vue'
import type { UiTag } from '@ui/components/UiTag/UiTagActive/UiTagActive.types'
import type { SortingOptions } from '../../types/CeNews.types'
import type { CeNews } from './T3CeNews_pi1.types'

/**
 * General news data
 */
export function useNews(data: ComputedRef<CeNews | null>) {
  const listOrSelectedList
    = data.value?.settings?.action === 'list'
    || data.value?.settings?.action === 'selectedList'

  const isDefaultList = computed<boolean>(
    () =>
      data.value?.settings?.action === 'list'
      && data.value?.settings?.templateLayout === 'default',
  )
  const isHorizontalList = computed<boolean>(
    () =>
      listOrSelectedList
      && data.value?.settings?.templateLayout === 'horizontal',
  )
  const isExpertsList = computed<boolean>(
    () => data.value?.settings?.templateLayout === 'experts',
  )
  const isFeaturedList = computed<boolean>(
    () =>
      listOrSelectedList && data.value?.settings?.templateLayout === 'featured',
  )
  const isRelatedArticleList = computed<boolean>(
    () =>
      listOrSelectedList && data.value?.settings?.templateLayout === 'single',
  )
  const isListNews = computed<boolean>(
    () => data.value?.settings?.action === 'list',
  )

  const isMainHorizontalList = computed<boolean>(
    () => data.value?.settings?.templateLayout === 'mainHorizontal',
  )
  const isForYouList = computed<boolean>(() => {
    return (
      listOrSelectedList && data.value?.settings?.templateLayout === 'forYou'
    )
  })
  const isTwoColumns = computed<boolean>(() => {
    return (
      listOrSelectedList && data.value?.settings?.templateLayout === '2columns'
    )
  })
  const topPromotedNewsColor = computed<string | null>(() => {
    return data.value?.settings?.topPromotedNewsColor ?? null
  })

  const bottomPromotedNewsColor = computed<string | null>(() => {
    return data.value?.settings?.bottomPromotedNewsColor ?? null
  })
  const activeTags = computed<UiTag[]>(() => data.value?.settings?.tags ?? [])

  const totalPages = ref<number>(data.value?.pagination?.pages ?? 0)
  const currentPage = ref<number>(data.value?.pagination?.current ?? 1)
  const currentPerPage = ref<string | number | undefined>(
    findFilterParameterInUrl('per')
      ? Number(findFilterParameterInUrl('per'))
      : 6,
  )

  const allNews = ref(data?.value?.list)

  watch(data, () => refreshData())
  const route = useRoute()
  watch(route, () => {
    currentPerPage.value = findFilterParameterInUrl('per')
      ? Number(findFilterParameterInUrl('per'))
      : 6
  })
  const refreshData = () => {
    totalPages.value = data.value?.pagination?.pages ?? 0
    currentPage.value = data.value?.pagination?.current ?? 1
    allNews.value = data?.value?.list
  }

  return {
    data,
    isDefaultList,
    isHorizontalList,
    isExpertsList,
    isFeaturedList,
    isRelatedArticleList,
    isListNews,
    isForYouList,
    isTwoColumns,
    isMainHorizontalList,
    totalPages,
    currentPage,
    currentPerPage,
    allNews,
    activeTags,
    topPromotedNewsColor,
    bottomPromotedNewsColor,
  }
}

/**
 * Sort functionality
 */
export function useNewsSort(data: ComputedRef<CeNews | null>) {
  interface SelectOption {
    label: string
    value: string
  }

  const selectedSortOption = ref<string>('')
  const currentSort = computed<SelectOption>(() => {
    const { t } = useI18n()
    const key = findFilterParameterInUrl('sort') ?? ''
    return {
      label:
        data.value?.settings?.sorting?.options?.[key as keyof SortingOptions]
        ?? t('sort'),
      value: key ?? '',
    }
  })
  const sortOptions = computed<SelectOption[]>(() => {
    const options = data.value?.settings?.sorting?.options

    if (!options) {
      return []
    }

    return Object.keys(options).map(
      (key: string) => {
        return {
          label: data.value?.settings?.sorting?.options?.[key as keyof SortingOptions] ?? '',
          value: key,
        }
      },
    )
  })

  return {
    selectedSortOption,
    sortOptions,
    currentSort,
  }
}

/**
 * Items to show functionality
 */
export function useNewsItemsToShow(data: ComputedRef<CeNews | null>) {
  const selectedItemsToShowOption = ref<string>('')
  const itemsToShowOptions = computed<number[]>(
    () => data.value?.settings?.itemsPerPage?.options ?? [6, 12, 18, 24],
  )

  return {
    selectedItemsToShowOption,
    itemsToShowOptions,
  }
}

/**
 * Translations
 */
export const CeNewsLabels = computed(() => {
  const { t } = useI18n()

  return {
    sortSelect: {
      placeholder: t('sort'),
    },
    pagination: {
      numberOfItemsToDisplay: {
        label: t('cms_display'),
      },
    },
    relatedArticles: {
      title: t('cms_related_articles'),
      relatedItem: {
        actionButton: t('see_more'),
      },
    },
    shareSocial: {
      title: t('cms_share_our_blog_post'),
    },
    readTime: t('min'),
  }
})

export function findFilterParameterInUrl(parameter: string): string | undefined {
  const router = useRoute()
  const routerSlugs: string | string[] = router.params.slug
  let transformedSlugs = [] as string[]

  if (typeof routerSlugs === 'string') { transformedSlugs = [routerSlugs as string] }
  if (Array.isArray(routerSlugs) && routerSlugs.length) {
    transformedSlugs = routerSlugs as string[]
  }
  if (!transformedSlugs?.length) { return undefined }

  const parameterSlug = transformedSlugs?.filter(
    (slug: string) => slug?.includes(parameter),
  )
  return parameterSlug ? parameterSlug?.[0]?.split('-')?.[1] : undefined
}

/**
 * Generate news filter url, eg: /pl/pl/news/sort-asc/per-10
 * If null is provided as value, param will be removed from url
 */

export function getNewsFilterUrl(value: string | number | null, param: string, options: {
  scrollToStories?: boolean
  resetFilters?: boolean
  isTag?: boolean
} = {
  scrollToStories: true,
  resetFilters: false,
  isTag: false,
}): string {
  if (!param) { return '' }
  const route = useRoute()
  const meta = useT3Meta()
  const paramName = options.isTag ? param : param.replace('-', '')
  const baseParamsOrder = ['sort-', 'per-', 'page-']
  const isParamName = options.isTag ? '' : `${paramName}-`
  let slugs = JSON.parse(JSON.stringify((route.params?.slug ?? []) as string[]))
  const query = route.query
  if (options?.resetFilters && value) {
    const canonicalUrl = meta?.metaData?.value?.canonical?.href?.slice(
      1,
    ) as string
    if (canonicalUrl) {
      slugs = canonicalUrl.split('/')
    }
  }
  const paramSlugIndex = slugs.findIndex((slug: string) =>
    slug.includes(paramName),
  )
  const sortSlugs = (arr, baseOrder) => {
    arr.push(`${isParamName}${value}`)

    const filtered = arr.filter((element) => {
      return baseOrder.some(item => element.includes(item))
    })

    const sorted = filtered.sort((a, b) => {
      const aMatch = baseOrder.findIndex(item => a.includes(item))
      const bMatch = baseOrder.findIndex(item => b.includes(item))

      return aMatch - bMatch
    })

    const left = arr.filter(element => !filtered.includes(element))

    return [...left, ...sorted]
  }
  if (value === null) {
    slugs.splice(paramSlugIndex, 1) // remove param if value is null

    if (options.isTag) {
      // remove search param if no tag left in url
      const searchIndex = slugs.indexOf('search')
      if (searchIndex !== -1) {
        slugs.splice(searchIndex, 1)
      }
    }
  }
  else if (paramSlugIndex > -1) {
    // replace value if param exists
    slugs[paramSlugIndex] = `${isParamName}${value}`
  }
  else {
    slugs = sortSlugs(slugs, baseParamsOrder)
  }
  let decodeParams = ''
  if (Object.keys(query).length !== 0) {
    const params = new URLSearchParams(query)
    decodeParams = `?${decodeURIComponent(params.toString())}`
  }
  // it is because logged user have different section to scroll
  // and this section has different color
  let sectionToScroll = '#stories'
  if (import.meta.client) {
    const isSelectForYouExsist = document.getElementById('scroll-news')
    sectionToScroll = isSelectForYouExsist ? '#scroll-news' : '#stories'
  }

  const storiesListHash = options?.scrollToStories
    ? sectionToScroll
    : '#prevent-scroll'
  return `/${slugs.join('/')}${decodeParams}${storiesListHash}`
}
