import type { UiCookiesManagerState } from '@ui/components/UiCookiesManager/UiCookiesManager.types'
import { useRegionalCookie } from '../useRegionalStorage'

export default function useCookiesManager() {
  const COOKIE_NAME = 'cookie-rwl-settings'
  const SSR_ERROR
    = 'useCookiesManager should not be used in SSR due to Varnish caching'
  const defaultState = {
    necessary: false,
    analytical: false,
    advertising: false,
  }

  const setCookiesManagerState = (cookie: UiCookiesManagerState) => {
    if (import.meta.server) {
      console.error(SSR_ERROR)
      return
    }

    const TIME_COOKIE_CONSENT_LASTS_IN_SECONDS = 2 * 30 * 24 * 60 * 60 // 60 days
    const settingsCookie = useRegionalCookie(COOKIE_NAME, {
      default: () => defaultState,
      maxAge: TIME_COOKIE_CONSENT_LASTS_IN_SECONDS,
    })

    if (settingsCookie.value) {
      settingsCookie.value = cookie
    }

    // reload page to make sure that analytical scripts will be added/removed properly
    window.location.reload()
  }

  const cookiesState = computed<UiCookiesManagerState>(() => {
    if (import.meta.server) {
      console.error(SSR_ERROR)
      return defaultState
    }

    const state = useRegionalCookie(COOKIE_NAME, {
      default: () => defaultState,
    }).value
    if (!state) {
      return defaultState as UiCookiesManagerState
    }
    return state
  })

  return {
    setCookiesManagerState,
    cookiesState,
  }
}
