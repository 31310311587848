import { useWindowSize } from '@vueuse/core'

export default function useContainerPadding() {
  const { width } = useWindowSize()
  const containerClassSize = 1366
  const containerClassPaddingAfterReachSize = 30
  const containerClassPadding = 40
  const containerPadding = ref(0)

  const calculatePadding = () => {
    if (width.value > containerClassSize) {
      containerPadding.value = (width.value - (containerClassSize - 2 * containerClassPaddingAfterReachSize)) / 2
      return
    }
    containerPadding.value = containerClassPadding
  }

  watch(width, () => {
    calculatePadding()
  }, {
    immediate: true,
  })

  return {
    containerPadding,
  }
}
