import type { IterableElement } from 'type-fest'
import type {
  ClearableFilterInterface,
  SelectedFiltersInterface,
} from '@ecom/composables/useFilters/useFilters.types'
import type {
  Attribute,
  CatalogProductsQuery,
  CategoryInterface,
  CategoryListQuery,
  ProductAttributeFilterInput,
  ProductAttributeSortInput,
} from '#gql'

// TODO: Replace with global magento type (page param is different - currentPage)
export interface M2CategoryParameters {
  url?: string
  search?: string
  pageSize?: number
  page?: number
  attributes?: Attribute[]
  filters?: ProductAttributeFilterInput
  sort?: ProductAttributeSortInput
}

export interface CatalogStoreFetchingState {
  pending: boolean
  error: null | {
    message: string
    id: string
  }
  [key: string]: unknown
}

export type CatalogStoreStateCategoryList = IterableElement<NonNullable<CategoryListQuery['categories']>['items']>[] | null

export interface CatalogStoreState {
  category: CategoryInterface | null
  catalogProducts: CatalogProductsQuery['products'] | null
  categoryList: CatalogStoreStateCategoryList
  categoryBreadcrumbs: string[]
  allFiltersVisible: boolean
  selectedFilters: SelectedFiltersInterface | null
  clearableFilters: ClearableFilterInterface[]
  params: M2CategoryParameters
  _states: {
    [key: string]: CatalogStoreFetchingState
  }
}

export const CatalogProductCustomAttributesDefaults: string[] = [
  'labels_ecommerce',
  'brand',
  'product_pos_color',
  'ecommerce_packing_type',
  'oryginal_unit',
  'erp_packaging_and_quantity',
  'certificates',
  'tax_class_id',
]
