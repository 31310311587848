import { default as _91_46_46_46slug_93kQrQS6Yn3wMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexCWUG0LkcuRMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesJOqzt5x9t8Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companylyQszT68XtMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactlfciCJiK1nMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexvWAJ5jzKqCMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as lists8EYsfOMaU9Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93dKcld6auNdMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orders2ped2OmfelMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingYugrvCpsp9Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userE89iIWaxb6Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editEvC2cH1tCGMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexfQRddOA8mGMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as new1S9SvFWsBXMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as edit2QbLDNlPqyMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexmx1ZXitxXZMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profile5jCF7Kj4c7Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as account0oBQPROpoaMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93UP4EWErO86Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryAqtRqHb4hcMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartpXAoWDokCUMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45payment1UM8yXf8P2Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summarykvDiGUG3uCMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutKBAGVwsKRTMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPassword3PwP671bI3Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordPTWhMW2TVdMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexdoszaEx11XMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexzgiGohB9PSMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productkDsBscMOJhMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93O2gt9NYNisMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexm8BWB9bACYMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registera9LXomkq3HMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveWqCumGPg8DMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsYo9xkFq2fGMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeWyXa7UJm4NMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchOrHbC0mjHhMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionnLpcv10iBLMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectioniSZD4lgwQWMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexRIRIZ0UzvnMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientJgC1YOVKUYMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementSDj82HlFMUMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementGremWe6oFCMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionN3gJDPTKNKMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeader4SwtDtxncSMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionyfPgKk7LNCMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugXjcLVgmDofMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugForm21BetCKKlUMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModal4yYXCp9SblMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugStickyTMGaoJroQVMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaCUX1lvz4UVMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeft4vex8lCw0JMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightm7QU59E9AnMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationMrvYiC4GE6Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionNl3R6XPfChMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slides9gbv82iPSCMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionbwnvzFOphzMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationCdVsTG9RwgMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeight4wOGOiEit1Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexQka4cJtuk0Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsThDgeIeD95Meta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPage3eUXiMTXVAMeta } from "/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageU4nCegX5B3Meta } from "/home/ecomadm/app/releases/168/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93kQrQS6Yn3wMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-cms/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: account0oBQPROpoaMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account.vue").then(m => m.default || m),
    children: [
  {
    name: addressesJOqzt5x9t8Meta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesJOqzt5x9t8Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: companylyQszT68XtMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companylyQszT68XtMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/company.vue").then(m => m.default || m)
  },
  {
    name: contactlfciCJiK1nMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactlfciCJiK1nMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: lists8EYsfOMaU9Meta?.name ?? "locale-account-lists",
    path: "lists",
    meta: lists8EYsfOMaU9Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: orders2ped2OmfelMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orders2ped2OmfelMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payments_45and_45billingYugrvCpsp9Meta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingYugrvCpsp9Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userE89iIWaxb6Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editEvC2cH1tCGMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexfQRddOA8mGMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: new1S9SvFWsBXMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: edit2QbLDNlPqyMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmx1ZXitxXZMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexmx1ZXitxXZMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue").then(m => m.default || m)
  },
  {
    name: profile5jCF7Kj4c7Meta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profile5jCF7Kj4c7Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/category.vue").then(m => m.default || m),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93UP4EWErO86Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkoutKBAGVwsKRTMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutKBAGVwsKRTMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: cartpXAoWDokCUMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartpXAoWDokCUMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45payment1UM8yXf8P2Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summarykvDiGUG3uCMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPassword3PwP671bI3Meta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordPTWhMW2TVdMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/event/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productkDsBscMOJhMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/product.vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93O2gt9NYNisMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexm8BWB9bACYMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue").then(m => m.default || m)
  },
  {
    name: registera9LXomkq3HMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registera9LXomkq3HMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-customer/pages/[locale]/register.vue").then(m => m.default || m)
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveWqCumGPg8DMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/resolve.vue").then(m => m.default || m)
  },
  {
    name: searchOrHbC0mjHhMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchOrHbC0mjHhMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search.vue").then(m => m.default || m),
    children: [
  {
    name: cmsYo9xkFq2fGMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsYo9xkFq2fGMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search/cms.vue").then(m => m.default || m)
  },
  {
    name: storeWyXa7UJm4NMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeWyXa7UJm4NMeta || {},
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-ecom/pages/[locale]/search/store.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/168/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/168/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]