import type { Ref } from 'vue'
import { nextTick, ref } from 'vue'
import {
  Map,
  type Popup,
} from 'mapbox-gl'
import { addPoints } from './UiMapContainer.helpers'
import { type ExtraOption, type UiMapOptions } from './UiMapContainer.types'

export function useUiMap(container: Ref<HTMLElement | null>) {
  const map = ref<Map | null>(null)
  const popup = ref<Popup | null>(null)

  async function initMap(options: UiMapOptions) {
    await nextTick()

    map.value = new Map({
      container: container.value, // container ID
      style: options.styleToken ?? 'mapbox://styles/mapbox/streets-v12', // style URL
      center: options.center ?? [17.099, 51.129], // starting position [lng, lat], deafult poland center
      zoom: options.zoom ?? 9, // starting zoom
      accessToken: options.accessToken ?? '',
      cooperativeGestures: true, // disable touch zoom
      interactive: options.interactive ?? true, // user interaction
    })

    if (options.extraOptions) {
      initOptions(options)
    }

    if (options.points) {
      map.value.on('load', () => {
        addPoints(map.value, popup.value, options)
      })
    }
  }

  function initOptions(options: UiMapOptions) {
    options.extraOptions.forEach((extraOption: ExtraOption) => {
      extraOption(map.value)
    })
  }

  return {
    initMap,
  }
}
