import { defineStore } from 'pinia'
import { useCatalogQueries } from '@ecom/composables/useCatalogQueries'
import type {
  ClearableFilterInterface,
  SelectedFiltersInterface,
} from '@ecom/composables/useFilters/useFilters.types'
import { mapProductsAttributesList } from '@ecom/helpers/products'
import { useProductQueries } from '../composables/product/useProductQueries/useProductQueries'
import { CatalogProductCustomAttributesDefaults, type CatalogStoreState, type M2CategoryParameters } from './catalog.types'
import type {
  CatalogProductsQueryVariables,
  CategoryListQueryVariables,
  CategoryQueryVariables,
} from '#gql'

const CatalogProductCustomAttributes = mapProductsAttributesList(
  CatalogProductCustomAttributesDefaults,
)

export const useCatalogStore = defineStore('catalog', {
  state: (): CatalogStoreState => ({
    category: null,
    catalogProducts: null,
    categoryList: null,
    categoryBreadcrumbs: [],
    allFiltersVisible: false,
    selectedFilters: null,
    clearableFilters: [],
    params: {
      url: '',
      search: '',
      page: 1,
      pageSize: 9,
      attributes: CatalogProductCustomAttributes,
      filters: {},
      sort: {},
    },
    _states: {
      category: {
        pending: false,
        error: null,
      },
      catalogProducts: {
        pending: false,
        error: null,
      },
      categoryList: {
        pending: false,
        error: null,
      },
    },
  }),
  actions: {
    async getCategory(params?: CategoryQueryVariables) {
      this._states.category.pending = true
      this._states.category.error = null

      try {
        const { getCategory } = useCatalogQueries()
        const data = await getCategory(params || this.mapCategoryParamsForQuery)
        this.category = data?.categories?.items?.[0] ?? null
        return data
      }
      catch (error) {
        const { $parseGqlError } = useNuxtApp()
        this._states.category.error = $parseGqlError(error)
        throw error
      }
      finally {
        this._states.category.pending = false
      }
    },
    async getCatalogProducts(params: CatalogProductsQueryVariables) {
      this._states.catalogProducts.pending = true
      this._states.catalogProducts.error = null

      try {
        const { getCatalogProducts } = useProductQueries()
        const data = await getCatalogProducts(params)
        this.catalogProducts = data ?? null
        return data ?? null
      }
      catch (error) {
        const { $parseGqlError } = useNuxtApp()
        this._states.catalogProducts.error = $parseGqlError(error)
        throw error
      }
      finally {
        this._states.catalogProducts.pending = false
      }
    },
    async getCategoryList(params?: CategoryListQueryVariables) {
      const { getCategoryList } = useCatalogQueries()
      this._states.categoryList.pending = true
      this._states.categoryList.error = null

      try {
        const data = await getCategoryList(
          params || {
            // MQ== is 1 in base64
            parentUid: 'MQ==',
            nested: true,
          },
        )
        const items = data.categories?.items?.[0]?.children || null
        this.categoryList = items
        return items
      }
      catch (error) {
        const { $parseGqlError } = useNuxtApp()
        this._states.categoryList.error = $parseGqlError(error)
        throw error
      }
      finally {
        this._states.categoryList.pending = false
      }
    },
    setParams(params: M2CategoryParameters) {
      Object.assign(this.params, params)
    },
    setSelectedFilters(filters: SelectedFiltersInterface | null) {
      this.selectedFilters = filters
    },
    setClearableFilters(filters: ClearableFilterInterface[]) {
      this.clearableFilters = filters
    },
    toggleVisibleFiltersCount(value: boolean) {
      this.allFiltersVisible = !value
    },
    setBreadcrumbs(slug: string | string[]) {
      this.categoryBreadcrumbs
        = (typeof slug === 'string' ? [slug] : slug) ?? []
    },
  },
  getters: {
    mapCategoryParamsForQuery: (state): CategoryQueryVariables => ({
      url: state.params.url!,
    }),
    mapCatalogProductsParamsForQuery: (
      state,
    ): CatalogProductsQueryVariables => ({
      search: state.params?.search,
      pageSize: state.params?.pageSize,
      currentPage: state.params?.page,
      attributes: state.params?.attributes,
      filters: state.params?.filters,
      sort: state.params?.sort,
    }),
    categoryError: state => state._states.category.error,
    catalogProductsError: state => state._states.catalogProducts.error,
    isCategoryPending: state => state._states.category.pending,
    isCatalogProductsPending: state => state._states.catalogProducts.pending,
    isCategoryWithProductsPending: state =>
      state._states.category.pending || state._states.catalogProducts.pending,
    isCategoryListPending: state => state._states.categoryList.pending,
  },
})
