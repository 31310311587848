import { type AppNavLink } from './AppNav.types'
import { type UiNavLink } from '~/modules/rawlplug-ui/components/UiNav/UiNav.types'

export interface NavTab {
  id: string
  title: string
  showSearch?: boolean
}

export function useAppNavState() {
  const { t } = useI18n()
  const customCategories = useState<AppNavLink[] | undefined>('appNavCustomCategories', () => [])
  const breadcrumbs = useState<UiNavLink[]>('appNavBreadcrumbs', () => [])

  const navTabs = useState<NavTab[]>('appNavTabs', () => [
    { id: 'categoryTree', title: t('categories'), showSearch: true },
  ])
  const activeTabId = useState('appNavCurrentTab', () => 'categoryTree')

  const activeTab = computed(() => {
    return navTabs.value.find(navTab => navTab.id === activeTabId.value)
  })

  const shouldBeVisible = useState('visibleAppNav', () => false)

  return {
    navTabs,
    activeTabId,
    activeTab,
    customCategories,
    breadcrumbs,
    shouldBeVisible,
  }
}
