import type { ComputedRef } from 'vue'
import type { T3NewsDetailData } from './T3CeNews_newsdetail.types'

/**
 * General news detail data
 */
export function useNewsDetail(data: ComputedRef<T3NewsDetailData>) {
  const nuxtApp = useNuxtApp()
  const newsDetail = computed(() => data.value?.detail)
  const currentLocale = useT3i18nState()

  const newsDetailSchema = computed(() => {
    const schema = {
      '@type': 'BlogPosting',
      'headline': newsDetail.value?.title || '',
      'description': newsDetail.value?.teaser || '',
      'inLanguage': currentLocale.value,
    }

    const author = computed(() => newsDetail.value?.author[0])
    const defaultImage = computed(
      () => newsDetail.value?.media?.[0]?.images?.defaultImage,
    )

    if (defaultImage.value?.publicUrl) {
      schema.image = nuxtApp.runWithContext(() => {
        const $img = useImage()
        return $img(defaultImage.value.publicUrl)
      })
    }

    if (newsDetail.value?.datetime) {
      const datePattern = /(\d{2})-(\d{2})-(\d{4})/
      const articleDate = new Date(
        newsDetail.value.datetime.replace(datePattern, '$3-$2-$1'),
      )?.toISOString()
      schema.datePublished = articleDate
      schema.dateModified = articleDate
    }

    if (author.value) {
      schema.author = [
        {
          name: `${author.value?.firstname} ${author.value?.lastname}`,
          url: author.value?.link || '',
        },
      ]
    }
    return schema
  })

  return {
    data,
    newsDetail,
    newsDetailSchema,
  }
}

export const CeNewsDetailLabels = computed(() => {
  const { t } = useI18n()

  return {
    shareSocial: {
      title: t('cms_share_our_blog_post'),
    },
    relatedArticles: {
      title: t('cms_related_articles'),
      relatedItem: {
        actionButton: t('see_more'),
      },
    },
    authorInfo: {
      title: t('cms_about_an_author'),
      moreFromAuthor: t('cms_more_from_the_author'),
    },
    tableOfContentsTitle: t('table_of_contents'),
    readTime: t('min'),
  }
})
