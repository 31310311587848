import { defineStore } from 'pinia'
import { cleanDoubleSlashes, withoutTrailingSlash } from 'ufo'
import type { MenuItem } from '@ui/components/UiMenuSubpages/UiMenuSubpages.types'
import type { IterableElement } from 'type-fest'
import { useCatalog } from '@ecom/composables/useCatalog'
import { useQuery } from '@ecom/composables/useQuery/useQuery'
import { useSearchQueries } from '@ecom/composables/useSearch/useSearchQueries/useSearchQueries'
import type { GqlError } from 'nuxt-graphql-client'
import { useCustomer } from '@customer/composables/useCustomer'
import type { CatalogStoreStateCategoryList } from './catalog.types'
import type { CatalogProductsQuery, CatalogProductsQueryVariables, SearchAggregationsQuery } from '#gql'
// Do not remove 'useRoute' import - https://github.com/nuxt/nuxt/issues/19204
import { useRoute } from '#vue-router'
import { FilterMatchTypeEnum } from '#gql/default'

export enum SearchRouteName {
  MAIN = 'search-page',
  STORE = 'search-store-page',
  CMS = 'search-cms-page',
}

export enum SearchUrlPart {
  STORE = 'store',
  CMS = 'cms',
}

export interface T3SearchParameters {
  search: string
  page: string | number | null
}
export interface Typo3SearchResultPaginationPage {
  link: string
  page: number
}

export interface Typo3SearchResultPagination {
  current: string | null
  currentPage: number | null
  next: string | null
  pages: Typo3SearchResultPaginationPage[]
  prev: string | null
}

export interface Typo3SearchResultItem {
  title: string
  _id: string | number
  image: string | null
  slug: string | null
  teaser: string
  category: unknown
}

export interface Typo3SearchResult {
  maxScore?: number
  pagination: Typo3SearchResultPagination
  results: Typo3SearchResultItem[]
  timedOut?: boolean
  total: number
}

export interface SearchResultsReturnType {
  magento: {
    data: CatalogProductsQuery['products'] | null
    aggregations: NonNullable<SearchAggregationsQuery['products']>['aggregations'] | null
    tree: CatalogStoreStateCategoryList
  }
  typo3: {
    data: Typo3SearchResult | null
    tree: unknown
  }
}

export interface SearchResultsPendingState {
  magento: {
    data: boolean
    aggregations: boolean
    tree: boolean
  }
  typo3: {
    data: boolean
    tree: boolean
  }
}

export enum SearchBarOptions {
  DEFAULT = 'default',
  NAME = 'name',
  SKU = 'sku',
  EAN = 'ean',
  VERSUS = 'versus',
}

export const SearchBarFilterName = {
  [SearchBarOptions.NAME]: 'name',
  [SearchBarOptions.SKU]: 'sku_matchable',
  [SearchBarOptions.EAN]: 'barcode',
  [SearchBarOptions.VERSUS]: 'aggregated_concurrency_index',
}

const useSearchStore = defineStore('search', () => {
  const router = useRouter()
  const route = useRoute()
  const { currentRoute } = router
  const { initialData } = useT3Api()
  const app = useNuxtApp()
  const { $i18n } = app
  const { t } = $i18n
  const {
    catalogProducts,
    getProducts,
    getCategoryList,
    isCategoryListPending,
    isCatalogProductsPending,
    routeQuery,
    resetCatalog,
  } = useCatalog('search')
  const { isLoggedIn } = useCustomer()
  const { getParamsFromURL } = useQuery()
  const { getSearchAggregations } = useSearchQueries()
  const { localePath } = useT3Utils()

  const DefaultSearchResults = (): SearchResultsReturnType => ({
    magento: {
      data: null,
      aggregations: null,
      tree: null,
    },
    typo3: {
      data: null,
      tree: null,
    },
  })

  const pendingState = ref<SearchResultsPendingState>({
    magento: {
      data: false,
      aggregations: false,
      tree: false,
    },
    typo3: {
      data: false,
      tree: false,
    },
  })

  const errorState = ref<string | null>(null)
  const initialized = ref(false)
  const selectedTab = ref(SearchRouteName.STORE)
  const searchResults = ref<SearchResultsReturnType>(DefaultSearchResults())
  const wasSearchPageAccessedOnSSR = ref(false)

  const searchBarOptionsList = computed(() => {
    const options = [
      {
        label: t('ecom_default'),
        value: SearchBarOptions.DEFAULT,
      },
      {
        label: t('name'),
        value: SearchBarOptions.NAME,
      },
      {
        label: t('index'),
        value: SearchBarOptions.SKU,
      },
      {
        label: t('ecom_ean'),
        value: SearchBarOptions.EAN,
      },
    ]

    if (isLoggedIn.value) {
      options.push({
        label: 'Versus',
        value: SearchBarOptions.VERSUS,
      })
    }

    return options
  })

  const getDefaultSearchBarOption = () => searchBarOptionsList.value?.find(option => option.value === SearchBarOptions.DEFAULT) ?? searchBarOptionsList.value[0]

  const selectedSearchBarOption = ref(getDefaultSearchBarOption())
  const _getMagento2SearchResults = async (
    selectedSearchOption: SearchBarOptions,
    parameters?: CatalogProductsQueryVariables,
  ) => {
    let urlParams = parameters
    if (!urlParams) {
      urlParams = getParamsFromURL()
    }

    let response = null

    const attributeOptions = { match: decodeURIComponent(urlParams?.search ?? ''), match_type: FilterMatchTypeEnum.PARTIAL }
    const partialMatchTypeParams = { ...urlParams, search: '', enableAggregations: false }

    switch (selectedSearchOption) {
      case SearchBarOptions.NAME: {
        response = await getProducts({ ...partialMatchTypeParams, [SearchBarFilterName.name]: attributeOptions }) ?? null
        searchResults.value.magento.data = response
        break
      }

      case SearchBarOptions.SKU: {
        response = await getProducts({ ...partialMatchTypeParams, [SearchBarFilterName.sku]: attributeOptions }) ?? null
        searchResults.value.magento.data = response
        break
      }

      case SearchBarOptions.EAN: {
        response = await getProducts({ ...partialMatchTypeParams, [SearchBarFilterName.ean]: attributeOptions }) ?? null
        searchResults.value.magento.data = response
        break
      }

      case SearchBarOptions.VERSUS: {
        response = await getProducts({ ...partialMatchTypeParams, [SearchBarFilterName.versus]: attributeOptions }) ?? null
        searchResults.value.magento.data = response
        break
      }
      default: {
        response = await getProducts({ ...urlParams, enableAggregations: false }) ?? null
        searchResults.value.magento.data = response
        break
      }
    }

    return response
  }

  const getMagento2SearchAggregations = async () => {
    pendingState.value.magento.aggregations = true
    errorState.value = null

    const urlParams = getParamsFromURL()

    if (!urlParams?.search) {
      return
    }

    try {
      const { products } = await getSearchAggregations({ search: decodeURIComponent(urlParams.search) }) || {}
      if (products?.aggregations) {
        searchResults.value.magento.aggregations = products.aggregations
      }

      return products?.aggregations ?? null
    }
    catch (error) {
      const { $parseGqlError } = useNuxtApp()
      errorState.value = ($parseGqlError(error as GqlError)?.message) ?? ''
    }
    finally {
      pendingState.value.magento.aggregations = false
    }
  }

  const filterCategoryListTreeBasedOnCategoryAggregationsOptions = (
    categoryTree: CatalogStoreStateCategoryList,
    categoryOptions: NonNullable<IterableElement<NonNullable<CatalogProductsQuery['products']>['aggregations']>>['options'],
  ): CatalogStoreStateCategoryList => {
    return (categoryTree ?? []).map((node) => {
      const matchingFilter = (categoryOptions ?? []).find(filter => filter?.value === (node?.uid ?? ''))

      if (matchingFilter) {
        // If the node matches a filter, keep it and recursively filter its children
        return { ...node, children: filterCategoryListTreeBasedOnCategoryAggregationsOptions(node?.children || [], categoryOptions) }
      }

      // If the node doesn't match a filter, check its children
      const filteredChildren: CatalogStoreStateCategoryList = filterCategoryListTreeBasedOnCategoryAggregationsOptions(node?.children || [], categoryOptions)

      // Remove the node if it has no matching children

      if (Array.isArray(filteredChildren) && filteredChildren.length > 0) {
        return { ...node, children: filteredChildren }
      }

      return null
    }).filter(Boolean) // Remove null entries from the result
  }

  const getMagento2CategoryTree = async () => {
    const categoryTree = await getCategoryList()
    const searchAggregations = await getMagento2SearchAggregations()

    if (!searchAggregations) {
      return
    }

    const foundProductsCategoryUids = (searchAggregations).find(aggregation => aggregation?.attribute_code === 'category_uid')?.options ?? []
    searchResults.value.magento.tree = filterCategoryListTreeBasedOnCategoryAggregationsOptions(categoryTree, foundProductsCategoryUids)
  }

  const getTypo3SearchResults = async (parameters?: T3SearchParameters) => {
    const { $fetch } = useT3Api()
    let urlParams = parameters

    if (!urlParams) {
      urlParams = getParamsFromURL()
    }
    const { currentSiteOptions } = useT3Options()
    const baseUrl = currentSiteOptions.value.api.baseUrl
    const initialDataSearchUrl
      = initialData.value?.search?.searchUrl?.href ?? ''
    const searchUrl = new URL(
      withoutTrailingSlash(
        cleanDoubleSlashes(baseUrl + initialDataSearchUrl),
        true,
      ),
    )

    let pageParam = Number(urlParams?.page)

    if (!urlParams?.page) {
      pageParam = 1
    }

    const searchUrlParams = searchUrl.searchParams

    searchUrlParams.append('search', decodeURIComponent(urlParams?.search ?? ''))

    try {
      if (pageParam && pageParam <= 1) {
        const response: Typo3SearchResult | null = await $fetch(searchUrl.href)
        searchResults.value.typo3.data = response ?? null
        return response
      }

      const isPageAppended = searchUrlParams.has('page')

      if (isPageAppended && searchUrlParams.get('page') === '1') {
        searchUrl.searchParams.delete('page')
      }
      else {
        searchUrl.searchParams.append('page', pageParam.toString())
      }

      const response: Typo3SearchResult = await $fetch(searchUrl.href)
      searchResults.value.typo3.data = response ?? null
      return response
    }
    catch (error) {
      throw new Error(error as string)
    }
  }

  const getTypoPaginationUrl = (page: number) => {
    const route = useRoute()
    const currentQuery = route.query
    const searchUrlParams = new URLSearchParams()

    const queryEntries = [...Object.entries(currentQuery)]
    if (queryEntries.length) {
      queryEntries.forEach(([key, entry]) => {
        if (Array.isArray(entry)) {
          entry.forEach(value =>
            searchUrlParams.append(key, (value ?? '').toString()),
          )
        }
        else if (entry == null) {
          searchUrlParams.append(key, '')
        }
        else {
          searchUrlParams.append(key, entry.toString())
        }
      })
    }

    if (page) {
      searchUrlParams.set('page', page.toString())
    }
    else {
      searchUrlParams.delete('page')
    }

    return localePath(`search/${SearchUrlPart.CMS}?${searchUrlParams}`)
  }

  const typo3Pagination = computed(() => {
    const pagination = searchResults.value?.typo3?.data?.pagination ?? null

    if (!pagination) {
      return null
    }

    const {
      prev: prevUrl = '',
      current: currentUrl = '',
      next: nextUrl = '',
      currentPage,
      pages = [],
    } = pagination

    return {
      currentPage,
      totalPages: pages.length,
      pages,
      prevUrl,
      currentUrl,
      nextUrl,
    }
  })

  const typo3Results = computed(() => {
    const results = searchResults.value?.typo3?.data?.results ?? []
    if (!results.length) {
      return []
    }

    return results.map(
      ({ title, _id, image = '', slug, teaser, category = [] }) => ({
        uid: _id,
        media: image,
        title,
        moreLink: t('see_more'),
        category,
        slug,
        teaser,
      }),
    )
  })

  const setActiveSearchTab = (tab?: MenuItem) => {
    let id = (currentRoute.value.meta?.name
      ?? SearchRouteName.STORE) as SearchRouteName

    if (tab) {
      id = tab.id as SearchRouteName
    }

    selectedTab.value = id
  }

  const cmsTotalResults = computed(() => searchResults.value?.typo3?.data?.total ?? 0)
  const storeTotalResults = computed(
    () => catalogProducts.value?.total_count ?? 0,
  )
  const totalResultsCount = computed(() => cmsTotalResults.value + storeTotalResults.value)
  const searchTerm = computed(() => {
    const term = route.query?.term ?? ''
    return typeof term === 'string'
      ? decodeURIComponent(term)
      : decodeURIComponent(term?.[term.length - 1] ?? '')
  })
  const isCategoryTreePending = computed(() => pendingState.value.magento.aggregations || pendingState.value.magento.tree || isCategoryListPending.value)

  return {
    pendingState,
    selectedTab,
    initialized,
    setActiveSearchTab,
    searchResults,
    getProducts,
    getCategoryList,
    _getMagento2SearchResults,
    getMagento2CategoryTree,
    getTypo3SearchResults,
    catalogProducts,
    getTypoPaginationUrl,
    isCatalogProductsPending,
    isCategoryTreePending,
    typo3Pagination,
    typo3Results,
    routeQuery,
    getParamsFromURL,
    resetCatalog,
    cmsTotalResults,
    storeTotalResults,
    totalResultsCount,
    searchTerm,
    getDefaultSearchBarOption,
    searchBarOptionsList,
    selectedSearchBarOption,
    getMagento2SearchAggregations,
    wasSearchPageAccessedOnSSR,
  }
})

export { useSearchStore }
