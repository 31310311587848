import type { HeadSafe } from '@unhead/vue'
import { useCatalogStore } from '@ecom/stores/catalog'
import type { MagentoPageData } from '@cms/types/MagentoPage.types'
import { SearchRouteName } from '@ecom/stores/search'
import { useSearch } from '@ecom/composables/useSearch/useSearch'
import usePromotions from '../composables/usePromotions'
import { CheckoutRoutes } from '../types/checkout.types'

export interface MagentoDataOptions {
  categoryUID?: string[]
  productSKU?: string[]
  categoryCode?: string
}

/**
 * Fetch Magento page data for category, product and search pages.
 * Data comes from TYPO3 API, which aggregates hreflangs and promotions.
 */
export default defineNuxtPlugin(async (nuxtApp) => {
  const { hook } = nuxtApp
  const { $fetch } = useT3Api()
  const { currentLocale } = useT3i18n()
  const { setPromotions } = usePromotions()

  hook('ecom:page:loading:end', async ({ route, magentoPageData }) => {
    if (['category', 'product', SearchRouteName.STORE, CheckoutRoutes.CART].includes(route.meta.name)) {
      await getMagentoData({ page: route.meta.name, pageData: magentoPageData })
    }
  })

  const hreflangs = ref<HeadSafe['link']>([])
  useHead({ link: hreflangs })

  async function getMagentoData({ page, pageData }: { page: 'category' | 'product' | `${SearchRouteName.STORE}` | `${CheckoutRoutes.CART}`, pageData?: MagentoDataOptions }) {
    const options: MagentoDataOptions = {
      categoryUID: pageData?.categoryUID ? pageData.categoryUID : [],
      productSKU: pageData?.productSKU ? pageData.productSKU : [],
      categoryCode: pageData?.categoryCode ? pageData.categoryCode : '',
    }

    /**
     * If we are on category/search page, we can use data from store.
     * On product page we are using data from pageData parameter passed on page load finish.
     */
    if (page === 'category') {
      const { category, catalogProducts } = useCatalogStore()

      if (category?.uid) {
        options.categoryUID?.push(category.uid)
      }
      if (category?.code) {
        options.categoryCode = category.code
      }

      if (Array.isArray(catalogProducts?.items)) {
        catalogProducts.items.forEach((product) => {
          options.productSKU?.push(product?.sku || '')
        })
        options.productSKU = options.productSKU?.filter(sku => !!sku)
      }
    }

    // If we are on search page (ecom), we can use data from search store
    if (page === SearchRouteName.STORE) {
      const { catalogProducts } = useSearch()
      if (Array.isArray(catalogProducts.value?.items)) {
        catalogProducts.value.items.forEach((product) => {
          options.productSKU?.push(product?.sku || '')
        })
        options.productSKU = options.productSKU?.filter(sku => !!sku)
      }
    }

    await $fetch<MagentoPageData>(`/${currentLocale.value}/rest/v1/magento/page`, {
      params: {
        cat: options.categoryUID?.join(',') || undefined,
        sku: options.productSKU?.join(',') || undefined,
        catc: options.categoryCode || undefined,
      },
    }).then((data) => {
      if (data?.hreflangs) {
        hreflangs.value = Object.keys(data.hreflangs).map((langKey) => {
          if (!langKey || !data.hreflangs[langKey]) {
            return null
          }
          return {
            rel: 'alternate',
            hreflang: langKey,
            href: data.hreflangs[langKey],
            hid: langKey,
          }
        }).filter(item => item !== null)
      }

      if (data) {
        setPromotions(data?.promotions ?? [])
      }
    }).catch((error) => {
      console.error('Error fetching Magento page data:', error)
      hreflangs.value = []
    })
  }
})
