<template>
  <UiLoadingOverlay
    :text="loadingOverlaySettings.text"
    :visible="loadingOverlaySettings.visible"
    :battery="loadingOverlaySettings.battery"
    :progress="loadingOverlaySettings.progress"
    @visibility-changed="visibilityChanged"
  />
</template>

<script lang="ts" setup>
import UiLoadingOverlay from '@ui/components/UiLoadingOverlay/UiLoadingOverlay.vue'
import type { UiLoadingOverlayProps } from '@ui/components/UiLoadingOverlay/UiLoadingOverlay.types'
import { useUiState } from '@base/composables/useUiState'

const DEFAULT_SETTINGS = {
  visible: false,
  battery: undefined,
  progress: undefined,
  text: '',
}

// overlay settings (updatable by useUiState)
const loadingOverlaySettings = ref<UiLoadingOverlayProps>(DEFAULT_SETTINGS)

// add component to UiState
const { addComponent } = useUiState()
const loadingOverlayUiComponent = addComponent<UiLoadingOverlayProps>('loadingOverlay', loadingOverlaySettings.value)

// watch component settings changes
watch(loadingOverlayUiComponent, (updatedOverlaySettings) => {
  loadingOverlaySettings.value = Object.assign(loadingOverlaySettings.value, updatedOverlaySettings)
}, { deep: true })

function visibilityChanged(state: boolean) {
  // if overlay is hidden, reset settings
  if (!state) {
    loadingOverlaySettings.value = DEFAULT_SETTINGS
  }
}
</script>
