import type { GqlError } from 'nuxt-graphql-client'
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import { StorageSerializers } from '@vueuse/core'
import { useRegionalCookie, useRegionalStorage } from '@base/composables/useRegionalStorage'
import { type JwtPayload, jwtDecode } from 'jwt-decode'
import { defineStore } from 'pinia'
import { cleanDoubleSlashes, withoutTrailingSlash } from 'ufo'
import { useCustomerQueries } from '@customer/composables/useCustomerQueries/useCustomerQueries'
import type { CatalogStoreState, LogInFormValues, TokenData, TokenDataError } from '../types/customer'
import { type CustomerSectionData } from '../types/customerSectionData'

export const SectionDataDefaultSections: (keyof CustomerSectionData)[] = [
  'cart', // to show items in minicart icon
  'rawlplug_wishlist', // for category pages (to check if product is in wishlist)
  'messages', // to show system notifications from Magento
  'company', // to display company name in Account Sidebar
]

export const useCustomerStore = defineStore('customer', {
  state: (): CatalogStoreState => ({
    sectionData: useRegionalStorage('sectionData', null, undefined, { serializer: StorageSerializers.object }),
    token: useRegionalCookie('token', {
      secure: true,
    }),
    _states: {
      pending: false,
      error: null,
      success: null,
    },
  }),
  actions: {
    async logIn(
      url: string,
      userKey: string,
      passKey: string,
      tokenKey: string,
      values: LogInFormValues,
    ) {
      const { updateAbility } = useCustomerAcl()
      const { callHook } = useNuxtApp()
      const { $fetch } = useT3Api()
      this._states.pending = true
      this._states.error = null
      let sectionData = null
      try {
        const tokenData: TokenData | TokenDataError = await $fetch(url, {
          method: 'POST',
          body: new URLSearchParams({
            [userKey]: values.email,
            [passKey]: values.password,
            [tokenKey]: values.token,
          }),
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

        if ('error' in tokenData) {
          this._states.error = tokenData?.error
          throw new Error(tokenData?.error)
        }

        if (tokenData?.token) {
          const jwtData = jwtDecode<JwtPayload>(tokenData.token)
          const tokenCookie = useRegionalCookie('token', {
            secure: true,
            expires: jwtData?.exp ? new Date(jwtData.exp * 1000) : undefined,
          })

          this.token = tokenData.token
          tokenCookie.value = tokenData.token

          await nextTick()
          // get customer sectionData if token received
          sectionData = await this.refreshSectionData({ lazy: false })
          if (!sectionData) {
            throw new Error('Cannot fetch customer sectionData')
          }
          updateAbility(sectionData.acl)
          // set new headers for all requests after login
          this.setNewHeaders()
        }

        await callHook('customer:login', {
          email: values.email,
        })

        return {
          token: tokenData?.token,
          sectionData,
        }
      }
      catch (error) {
        throw new Error(error as string)
      }
      finally {
        this._states.pending = false
      }
    },
    async refreshSectionData(
      options: { lazy?: boolean, sections?: (keyof CustomerSectionData)[] } = {
        lazy: true,
        sections: [],
      },
    ) {
      if (import.meta.server) {
        console.error('Calling the refreshSectionData method is not allowed on the server side')
        return null
      }

      const { updateAbility } = useCustomerAcl()
      const { queryMagento } = useMagentoRestApi()

      const sections = options.sections?.join(',') || ''
      const SECTION_DATA_ENDPOINT = `V1/customer/sections/?sectionNames=${sections}`

      const sectionData = await queryMagento(SECTION_DATA_ENDPOINT, {
        cache: 'no-cache',
      })

      // due to Magento 2 bug, BE must return an array with one object inside
      if (Array.isArray(sectionData) && sectionData?.[0]) {
        // if there is already sectionData, merge it with new data
        if (this.sectionData) {
          Object.assign(this.sectionData, sectionData[0])
        }
        else {
          this.sectionData = sectionData[0]
        }

        if (this.sectionData) {
          updateAbility(this.sectionData.acl)
        }

        return this.sectionData
      }
      return null
    },
    async logOutCustomer(
      options: { redirectTo?: string | boolean } = {
        redirectTo: true,
      },
    ) {
      const { callHook } = useNuxtApp()
      const { initialData, $fetch } = useT3Api()
      const { currentLocale } = useT3i18n()
      const { updateAbility } = useCustomerAcl()
      const { redirectTo } = options
      const logoutUrl = withoutTrailingSlash(
        cleanDoubleSlashes(initialData.value?.loginData?.logoutUrl),
        true,
      ) // cleanup URL to prevent unnecessary redirects
      const route = useRoute()

      this._states.pending = true

      if (logoutUrl) {
        await $fetch(logoutUrl, {
          method: 'GET',
          cache: 'no-cache',
        })
      }

      updateAbility()
      this.resetCustomerData()
      await callHook('customer:logout')

      if (!redirectTo) {
        this._states.pending = false
        return
      }

      const isAuthPage = route.meta.middleware?.toString().includes('auth')
      const isFeaturePage = route.meta?.conditions?.flags?.length

      if (typeof redirectTo === 'string' && redirectTo.length) {
        this._states.pending = false
        return navigateTo(redirectTo)
      }

      if (isAuthPage || isFeaturePage) {
        await nextTick(async () => {
          this._states.pending = false
          return navigateTo(`/${currentLocale.value}`)
        })
      }

      this._states.pending = false
    },
    async resetPassword(variables: { email: string }) {
      const { $parseGqlError } = useNuxtApp()

      this._states.pending = true
      this._states.error = null

      const { passwordReset } = useCustomerQueries()
      try {
        const resetResponse = await passwordReset(variables)
        return Boolean(resetResponse.requestPasswordResetEmail)
      }
      catch (error) {
        this._states.error
          = $parseGqlError(error as GqlError)?.message || 'Error occurred'
      }
      finally {
        this._states.pending = false
      }
    },
    async changePassword(variables: {
      currentPassword: string
      newPassword: string
    }) {
      const { $parseGqlError } = useNuxtApp()
      this._states.pending = true
      this._states.error = null

      const { changePassword } = useCustomerQueries()
      try {
        const resetResponse = await changePassword(variables)
        return Boolean(resetResponse.changeCustomerPassword)
      }
      catch (error) {
        this._states.error
          = $parseGqlError(error as GqlError)?.message || 'Error occurred'
      }
      finally {
        this._states.pending = false
      }
    },
    resetCustomerData() {
      this.sectionData = null
      this.token = null
    },
    resetState() {
      this._states.pending = false
      this._states.error = null
      this._states.success = null
    },
    async refreshCustomerToken() {
      const initialData: Ref<T3InitialData> = useT3InitialDataState()
      const refreshTokenEndpoint = initialData.value.endpoints.user.refreshToken
      const { $fetch } = useT3Api()

      if (!refreshTokenEndpoint) {
        return await this.logOutCustomer()
      }

      try {
        // this request sends back set-cookie header with new token
        await $fetch(refreshTokenEndpoint, {
          method: 'PUT',
        })
        const newToken = useRegionalCookie('token').value
        if (newToken) {
          this.token = useRegionalCookie('token').value
          this.setNewHeaders()
        }
        else {
          await this.logOutCustomer()
          throw new Error('Cannot refresh customer token')
        }
      }
      catch (error: any) {
        console.error(error)
        await this.logOutCustomer()
        throw new Error('Cannot refresh customer token')
      }
    },
    setNewHeaders() {
      const { $setHeaders } = useNuxtApp()
      $setHeaders()
    },
    async getOrderInfo(orderNumber: string) {
      const { $parseGqlError } = useNuxtApp()

      this._states.pending = true
      this._states.error = null

      const { getCustomerOrder } = useCustomerQueries()
      try {
        return await getCustomerOrder({ orderNumber })
      }
      catch (error) {
        this._states.error
          = $parseGqlError(error as GqlError)?.message || 'Error occurred'
      }
      finally {
        this._states.pending = false
      }
    },
    async getRequestToken(): Promise<{ name: string, value: string }> {
      const { $fetch } = useT3Api()
      const initialData: Ref<T3InitialData> = useT3InitialDataState()
      this._states.pending = true
      try {
        const requestTokenEndpoint = initialData.value.endpoints.user?.requestToken
        if (!requestTokenEndpoint) {
          throw new Error('Cannot get customer token')
        }
        return await $fetch(requestTokenEndpoint, {
          method: 'POST',
          credentials: 'same-origin',
        })
      }
      catch (error: any) {
        console.error(error)
        throw new Error('Cannot get customer token')
      }
      finally {
        this._states.pending = false
      }
    },
  },
  getters: {
    customerData: state => state.sectionData,
    customerToken: state => state.token,
    customerState: state => state._states,
    isLoggedIn: (state) => {
      if (import.meta.server) {
        return Boolean(state.token)
      }
      else {
        return Boolean(state.token) && Boolean(state.sectionData)
      }
    },
    isCheckoutEnabled: state => state?.sectionData?.cart?.possible_onepage_checkout || true,
  },
  hydrate(storeState) {
    /**
     * Hydrate values from storage
     * https://pinia.vuejs.org/api/interfaces/pinia.DefineStoreOptionsInPlugin.html#hydrate
     */
    const customerDataStorage = useRegionalStorage('sectionData', null, undefined, {
      serializer: StorageSerializers.object,
    }) as CatalogStoreState['sectionData']
    const tokenStorage = useRegionalCookie('token', {
      secure: true,
    }) as CatalogStoreState['token']

    try {
      storeState.sectionData = customerDataStorage
      storeState.token = tokenStorage
    }
    catch {
      throw new Error('Error while hydrating customer store (sectionData)')
    }
  },
})
