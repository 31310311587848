import { createGtm, useGtm } from '@gtm-support/vue-gtm'
import type { AnalyticsEvent } from '../types/plugins/analytics'
import useCookiesManager from '../composables/useCookiesManager/useCookiesManager'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const GTM_ID = nuxtApp.$config?.public?.gtm as string | undefined
  if (!GTM_ID) { return }

  const { hook } = useNuxtApp()
  const { cookiesState } = useCookiesManager()

  if (cookiesState.value?.analytical) {
    useHead({
      link: [
        {
          rel: 'preconnect',
          href: 'https://www.googletagmanager.com',
          key: 'preconnect-gtm',
        },
      ],
    })

    nuxtApp.vueApp.use(
      createGtm({
        id: GTM_ID,
        defer: false,
        debug: import.meta.env.DEV,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
      }),
    )
    hook('analytics', (event) => {
      sendEventToGTM(event)
    })

    hook('customer:login', (payload) => {
      provideUsercomId()
      sendEventToGTM({
        event: 'login',
        ecommerce: {
          user_id: payload.email,
        },
      })
    })

    hook('customer:logout', () => {
      provideUsercomId()
      sendEventToGTM({ event: 'logout' })
    })

    hook('ecom:cart-add', (payload) => {
      sendEventToGTM({
        event: 'add_to_cart',
        ecommerce: {
          item: payload.sku,
        },
      })
    })

    /**
     * Initializes and manages the usercom ID in the GTM dataLayer.
     *
     * The provideUsercomId function is fired on 'customer:login' and 'customer:logout'
     * events and updates the usercom ID in the
     * dataLayer based on the user's login status. The user ID is retrieved
     * from the customer data if logged in, and set to null if not.
     */
    provideUsercomId()
  }

  /**
   * usercom support
   */
  function provideUsercomId() {
    const gtm = useGtm()
    const { isLoggedIn, customerData } = useCustomer()

    const dataLayer = gtm?.dataLayer()
    let userId = customerData.value?.usercom.user_id ?? null

    if (dataLayer === false) {
      return
    }

    if (!isLoggedIn.value) {
      userId = null
    }

    return dataLayer?.push({
      user_id: userId,
    })
  }

  /**
   * Send event to GTM
   * @param {object} event - GTM event and optional data
   */
  function sendEventToGTM(event: AnalyticsEvent) {
    const gtm = useGtm()
    const dataLayer = gtm?.dataLayer()
    if (dataLayer) {
      dataLayer.push(event)
    }
  }
})
