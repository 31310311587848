import type {
  CatalogProductsQueryVariables,
  GetProductByCategoryIdQueryVariables,
  MagentoCategoryProductsListLimitedQueryVariables,
  MagentoCategoryProductsListQueryVariables,
  ProductQueryVariables,
  RelatedProductsQueryVariables,
  SearchSuggestionsProductsQueryVariables,
  UpsellProductsQueryVariables,
} from '#gql'

function useProductQueries() {
  const product = async (variables: ProductQueryVariables) =>
    await GqlProduct(variables)

  const relatedProducts = async (variables: RelatedProductsQueryVariables) =>
    await GqlRelatedProducts(variables)

  const upsellProducts = async (variables: UpsellProductsQueryVariables) =>
    await GqlUpsellProducts(variables)

  const searchSuggestionsProducts = async (variables: SearchSuggestionsProductsQueryVariables) =>
    await GqlSearchSuggestionsProducts(variables)

  const getCatalogProducts = async (variables: CatalogProductsQueryVariables) => {
    const { products } = await GqlCatalogProducts(variables) || {}
    return products
  }

  const getProductByCategoryId = async (variables: GetProductByCategoryIdQueryVariables) =>
    await GqlGetProductByCategoryId(variables)

  const magentoCategoryProductsList = async (variables: MagentoCategoryProductsListQueryVariables) =>
    await GqlMagentoCategoryProductsList(variables)

  const magentoCategoryProductsListLimited = async (variables: MagentoCategoryProductsListLimitedQueryVariables) =>
    await GqlMagentoCategoryProductsListLimited(variables)

  return {
    product,
    relatedProducts,
    upsellProducts,
    searchSuggestionsProducts,
    getCatalogProducts,
    getProductByCategoryId,
    magentoCategoryProductsList,
    magentoCategoryProductsListLimited,
  }
}

export { useProductQueries }
