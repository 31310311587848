import type { Column } from '@ui/components/UiBaseTable/UiBaseTable.types'
import type {
  CartItemAvailabilityData,
  CartItemQuantityData,
  IndividualCustomerSinglePackagePrice,
  PackingImage,
  ProductName,
} from '@ecom/composables/checkout/useCartProductData/useCartProductData.types'
import { type ProductType } from './product.types'

export enum CheckoutRoutes {
  CHECKOUT = 'checkout',
  CART = 'checkout-cart',
  DELIVERY = 'checkout-delivery-and-payment',
  SUMMARY = 'checkout-summary',
}

export interface PlaceOrderData {
  ['delivery-address']: string
  ['shipping-method']: string
  ['payment-method']: string
  creditlimit: string
  ['additional-order-information']?: string
  cartId: string
}

export interface MappedCartItemProduct {
  productType: `${ProductType}`
  name: ProductName
  link: string
  userCode: string | null
  brand: {
    src: string
    alt: string
    height: number
  } | null
  sku: string | null | undefined
  barcode: string | null | undefined
  stockStatus: any
  leftInStockCount: number | null
  packing: {
    image: PackingImage
    description: string
  }
}

// types used in cart.vue in computed 'cartItems'
export interface MappedCartItem {
  uid: string
  product: MappedCartItemProduct
  splitPayment: boolean
  quantityDataChanged: boolean
  price: {
    individualCustomerSinglePackagePrice: IndividualCustomerSinglePackagePrice
    netPrice: number
    netPriceWithDiscount: number
    totalNetPrice: number | null | undefined
  }
  promotions?: {
    price: boolean
    cms: boolean
  }
  quantity: CartItemQuantityData
  availabilityData: CartItemAvailabilityData
  measurementUnit?: string | null
  image: {
    src: string
    width: number
    height: number
  }
}

// types used in summary.vue in computed 'cartItems'
export interface MappedOrderItem {
  name: ProductName
  link: string
  brand: {
    src: string
    alt: string
    height: number
  } | null
  sku: string
  barcode: string
  packing: {
    image: PackingImage
    description: string
  }
  price: {
    netPriceWithDiscount: number
    totalNetPrice: number | null | undefined
  }
  quantity: number | null
  availabilityStatus: boolean | undefined
  image: {
    src: string
    alt: string
    width: number
    height: number
  }
}

export enum AvailabilityDataIndexValues {
  // Availability 24-48h
  ONE_TWO_DAYS = '2',
  // Availability up to 72h
  UP_TO_THREE_DAYS = '3',
  // Availability within 7 days
  WITHIN_7_DAYS = '7',
  // Availability within 8 days
  WITHIN_8_DAYS = '8',
  // Availability within 14 days
  WITHIN_14_DAYS = '14',
  // Availability within 15 days
  WITHIN_15_DAYS = '15',
  // Availability up 21 days
  UP_TO_21_DAYS = '21',
  // Availability over 21 days
  OVER_21_DAYS = '999',
  // Product temporarily unavailable
  TEMPORARILY_UNAVAILABLE = '9999',
}

export const CUSTOMER_CART_RELATED_PRODUCTS_MIN_PAGE_SIZE = 3
export const CUSTOMER_CART_UPSELL_PRODUCTS_MIN_PAGE_SIZE = 3

export const CUSTOMER_CART_RELATED_PRODUCTS_MAX_PAGE_SIZE = 20
export const CUSTOMER_CART_UPSELL_PRODUCTS_MAX_PAGE_SIZE = 20

// Classes used both in M2CheckoutTable and inner tables with related/upsell products (M2CatalogProductsTable)
export function COMMON_CHECKOUT_TABLE_COLUMN_WIDTHS(cell: Column) {
  if (!cell?.name) {
    return ''
  }

  return {
    'min-w-[23ch]': cell?.name === 'amountOfPackages',
    'min-w-[18ch]':
      cell.name === 'packingType'
      || cell.name === 'netPriceForPackage'
      || cell.name === 'deliveryTime'
      || cell.name === 'totalNetPrice',
  }
}
export interface CheckoutProvider {
  initialized: Ref<boolean>
  commonLabels: Record<string, any>
  isCartDivisionFeatureEnabled: Ref<boolean>
}

export const CheckoutProviderKey: InjectionKey<CheckoutProvider>
  = Symbol('checkout-provider')
