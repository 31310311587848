import { MAX_CART_ROWS_DEFAULT } from '../stores/storeConfig.types'
import type { StoreConfigQuery } from '#gql'

const formatHelplineHour = (hour?: string | number | null) => typeof hour === 'string' || typeof hour === 'number' ? `${hour.toString().padStart(2, '0')}:00` : null
function formatHelplineDays(days: string | null | undefined, locale: string) {
  if (typeof days !== 'string') {
    return ''
  }

  const daysElements = days.split(',')

  if (!daysElements?.length || (daysElements?.length && daysElements.length < 2)) {
    return ''
  }

  const formatter = new Intl.DateTimeFormat(locale, { weekday: 'short' })

  const translatedDays = daysElements.map((day: string) => {
    const date = new Date()
    date.setDate(daysElements.indexOf(day) + 1) // Setting the day of the week in the Date object

    return formatter.format(date)
  })

  return `${translatedDays[0]} - ${translatedDays.at(-1)}`
}

export function mapStoreConfigData(storeConfig: NonNullable<StoreConfigQuery['storeConfig']>) {
  const {
    general_store_information_name: storeName = '',
    general_store_information_country_id: countryId = '',
    general_store_information_region_id: regionId = '',
    general_store_information_postcode: postcode = '',
    general_store_information_city: city = '',
    general_store_information_street_line1: street = '',
    general_store_information_merchant_vat_number: vatNumber = '',
    general_store_information_regon: regon = '',
    general_store_information_customer_service_number = '',
    general_store_information_customer_service_fax: fax = '',
    general_store_information_customer_service_email: email = '',
    rawlplug_contact_help_enabled: enableReportErrorModal = true,
    helpline_hour_from: helplineHourFrom,
    helpline_hour_to: helplineHourTo,
    helpline_days: helplineDays,
    locale = 'en_US',
    business_roles: businessRoles = [],
    rawlplug_catalog_max_cart_rows: maxCartRows = MAX_CART_ROWS_DEFAULT,
    ...data
  } = storeConfig

  const centralOfficeData = {
    storeName,
    countryId,
    regionId,
    postcode,
    city,
    street,
    vatNumber,
    regon,
  }

  const localLang = locale?.replace('_', '-') ?? ''

  const customerServiceData = {
    phoneNumber: general_store_information_customer_service_number,
    fax,
    email,
    helpline: {
      hours: {
        from: formatHelplineHour(helplineHourFrom),
        to: formatHelplineHour(helplineHourTo),
      },
      days: formatHelplineDays(helplineDays, localLang),
    },
  }

  const checkoutData = {
    maxCartRows,
  }

  return {
    ...data,
    locale,
    checkoutData,
    centralOfficeData,
    customerServiceData,
    enableReportErrorModal,
    businessRoles,
  }
}
