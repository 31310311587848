<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <Transition
    mode="out-in"
    appear
    leave-active-class="transition-opacity duration-200"
    enter-active-class="transition-opacity duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    @enter="toggleScrollLock(true, $el)"
    @after-enter="initLoader()"
    @after-leave="afterLeave()"
  >
    <div
      v-if="props.visible"
      ref="loadingOverlayEl"
      class="fixed inset-0 z-50 grid size-full cursor-wait grid-cols-1 place-content-center overflow-auto bg-blue/80 p-4 text-center backdrop-blur-sm"
    >
      <div class="flex flex-col gap-y-10">
        <p
          v-if="props.text"
          class="text-xl font-bold text-white"
        >
          {{ props.text }}
        </p>
        <div
          v-if="props.battery"
          id="battery-indicator"
          ref="batteryIndicatorEl"
          class="mx-auto w-full text-white"
          style="max-width: 162px"
          v-html="LoadingIconSVG"
        />
        <div
          v-if="typeof props.progress === 'number' || props.progress === ''"
          class="mx-auto h-2 w-full overflow-hidden rounded"
          style="max-width: 326px"
        >
          <div class="size-full bg-white/15">
            <!-- value progress bar -->
            <div
              v-if="typeof props.progress === 'number'"
              :style="`width: ${props.progress}%`"
              class="h-full bg-white transition-width"
            />

            <!-- indeterminate progress bar -->
            <div
              v-else
              class="indeterminate-progress-bar size-full bg-white"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import LoadingIconSVG from './loading-icon.svg?raw'
import type { UiLoadingOverlayProps } from './UiLoadingOverlay.types'

const props = withDefaults(defineProps<UiLoadingOverlayProps>(), {
  battery: true,
  progress: null,
})

const SPEED_MS = 800
const SPEED_CSS_VALUE = `${SPEED_MS}ms`

// loading overlay root element
const loadingOverlayEl = ref<HTMLElement | null>(null)
// loading indicator element
const batteryIndicatorEl = ref<SVGElement | null>(null)
// battery lights wrapper (<g/> element in SVG)
const batteryLightsEl = ref<SVGGElement | null>(null)
// last light in battery lights
const lastLightEl = ref<SVGRectElement | null>(null)

function initLoader() {
  batteryLightsEl.value = batteryIndicatorEl.value?.querySelector('.battery-lights') as SVGGElement
  lastLightEl.value = batteryLightsEl.value?.querySelector('rect:last-of-type') as SVGRectElement
  if (lastLightEl.value?.nodeName === 'rect') {
    lastLightEl.value.addEventListener('animationend', animationRestart)
  }
}

onBeforeUnmount(() => afterLeave())

function animationRestart() {
  batteryLightsEl.value.getAnimations({ subtree: true }).forEach((animation) => {
    animation.cancel()
    setTimeout(() => animation?.play?.(), 500)
  })
}

function afterLeave() {
  if (lastLightEl.value?.nodeName === 'rect') {
    lastLightEl.value.removeEventListener('animationend', animationRestart)
  }
  toggleScrollLock(false, loadingOverlayEl.value as HTMLElement)
}

function toggleScrollLock(state: boolean, element: HTMLElement) {
  if (!element) {
    console.error('There is no element provided to lock scroll')
    return
  }
  if (state === true) {
    disableBodyScroll(element, {
      reserveScrollBarGap: true,
    })
  }
  else {
    enableBodyScroll(element)
  }
}
</script>

<style lang="postcss">
#battery-indicator {
  .battery-lights {
    transition-duration: v-bind(SPEED_CSS_VALUE);
    transition-property: opacity;
    transition-timing-function: ease-in-out;

    rect:not(:first-child) {
      transition: inherit;
      animation-name: loadingOverlayFade;
      animation-iteration-count: 1;
      animation-duration: v-bind(SPEED_CSS_VALUE);
      animation-fill-mode: forwards;

      &:nth-child(2) {
        animation-delay: 0s;
      }
      &:nth-child(3) {
        animation-delay: v-bind(SPEED_CSS_VALUE);
      }
      &:nth-child(4) {
        animation-delay: calc(v-bind(SPEED_CSS_VALUE) * 2);
      }
    }
  }
}

.indeterminate-progress-bar {
  animation-duration: v-bind(SPEED_CSS_VALUE);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: loadingOverlayProgress;
  transform-origin: 0 50%;
}

@keyframes loadingOverlayFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingOverlayProgress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
